import { Box, Heading } from "@chakra-ui/react"
import React from "react"

interface Props {}

const HeroSection: React.FunctionComponent<Props> = () => {
  return (
    <Box py={["12em", "22em"]}>
      <Box
      // TODO: Animate this
      // opacity={0}
      // transform="translateY(4rem)"
      // transition="opacity .9s cubic-bezier(.01,.98,.15,1),transform .9s cubic-bezier(.01,.98,.15,1)"
      // _hover={{
      //   opacity: 1,
      //   transform: "translate(0rem)",
      // }}
      >
        <Heading fontSize={["2xl", "5xl"]}>
          We partner with agencies, brands and people to transform their
          creative vision into thoughtfully engineered digital products.
        </Heading>
      </Box>
    </Box>
  )
}

export default HeroSection
