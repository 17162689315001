import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Grid,
  GridItem,
  Image,
  List,
  ListItem,
  VStack,
  useDisclosure,
  Button,
  Container,
  Heading,
  HStack,
  Stack,
  Text,
  Input,
  FormLabel,
  FormControl,
  Textarea,
  Spacer,
} from "@chakra-ui/react"
import React from "react"
import { useState } from "react"
import { useForm } from "react-hook-form"

interface ModalProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactModal: React.FunctionComponent<ModalProps> = ({
  isOpen,
  onOpen,
  onClose,
}) => {
  const { register, handleSubmit } = useForm()
  const [isValid, setIsValid] = useState(false)

  const onSubmit = (data, e) => {
    e.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...data,
      }),
    })
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.log(error)
      })
    setIsValid(true)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="20px" marginTop="6rem" padding={2}>
        <ModalHeader>
          <Heading>Get in touch</Heading>
        </ModalHeader>
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input type="hidden" name="contact" value="contact" />
          <ModalBody>
            <Stack spacing="4">
              <Text>
                Send us a basic idea of what you're after. The details aren't
                important yet, just the big picture!
              </Text>
              <FormControl>
                <FormLabel htmlFor="name">Your name</FormLabel>
                <Input
                  border="1px solid black"
                  {...register("name")}
                  borderRadius="0px"
                  id="name"
                  type="name"
                  isRequired
                ></Input>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="email">Your email</FormLabel>
                <Input
                  border="1px solid black"
                  {...register("email")}
                  borderRadius="0px"
                  id="email"
                  type="email"
                  isRequired
                ></Input>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="brief">Project brief</FormLabel>
                <Textarea
                  border="1px solid black"
                  {...register("brief")}
                  borderRadius="0px"
                  id="brief"
                  type="brief"
                  isRequired
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Text fontSize="lg" display={isValid ? "inline-block" : "none"}>
              Thanks! We'll be in touch
            </Text>
            <Spacer />
            <Button
              type="button"
              variant="link"
              fontWeight="normal"
              color="black"
              fontSize="lg"
              marginRight={4}
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              fontWeight="normal"
              type="submit"
              boxSizing="border-box"
              backgroundColor="black"
              color="white"
              borderRadius="100vw"
              fontSize="lg"
              border="2px solid transparent"
              padding="1.2em 2vw"
              _hover={{
                border: "2px solid black",
                color: "black",
                backgroundColor: "white",
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

const Contact: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <section id="contact">
      <ContactModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
      <Container align="center" paddingTop={[16, 32]} paddingX="0">
        <a href="https://rygrgttnw7b.typeform.com/to/Kvo428gN" target="_blank">
          <Button
            boxSizing="border-box"
            backgroundColor="black"
            color="white"
            borderRadius="50px"
            width={["full", "auto"]}
            fontSize={["xl", "4xl"]}
            border="2px solid transparent"
            padding="1.2em 30px"
            _hover={{
              border: "2px solid black",
              color: "black",
              backgroundColor: "white",
            }}
            // onClick={onOpen}
          >
            Get in touch
          </Button>
        </a>
      </Container>
    </section>
  )
}

export default Contact
