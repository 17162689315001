import { Grid, GridItem, Heading, Stack } from "@chakra-ui/react"
import React from "react"
import ProjectCard from "../../../components/project-card"
import { Project } from "../../../types"

interface Props {
  projects: Project[]
}

const WorkSection: React.FunctionComponent<Props> = ({ projects }) => {
  return (
    <Stack spacing="16" as="section" id="work" paddingTop={[20, 32]}>
      <Heading>Selected work</Heading>
      <Grid
        templateColumns={{ md: "repeat(2, 1fr)", sm: "1fr" }}
        gridGap={[20, 12]}
      >
        {projects.map(item => (
          <GridItem key={item.clientName}>
            <ProjectCard {...item} />
          </GridItem>
        ))}
      </Grid>
    </Stack>
  )
}

export default WorkSection
