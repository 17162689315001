import {
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react"
import React from "react"

interface Props {}

const StudioSection: React.FunctionComponent<Props> = () => {
  return (
    <Stack spacing={[8, 16]} as="section" id="studio" paddingTop={[20, 32]}>
      <Heading>Our studio</Heading>
      <Heading fontSize={["3xl", "6xl"]} pb={[0, 8]}>
        We build digital products that are fast, beautiful and a delight to use
      </Heading>
      <Stack spacing="8">
        <Grid templateColumns={["1fr", "repeat(12, 1fr)"]} gridGap={12}>
          <GridItem colSpan={[1, 4]}>
            <Text fontSize="2xl">
              We approach development with engineering precision. Performance
              and usability are our first class citizens.
            </Text>
          </GridItem>
          <GridItem gridColumnStart={[0, 6]} colSpan={[1, 7]}>
            <Stack>
              <Text fontSize="lg">
                Our team doesn't use archaic and inefficient tools of old like
                WordPress, WebFlow and SquareSpace. Why? Good question. These
                tools are often jack of all trades, masters of none, and come
                packaged with a lot of bloated code and unnecessary plugins that
                slow down your website.
              </Text>
              <Text fontSize="lg">
                Our sites are a little bit different from the norm. We start
                your project from scratch, adding only what your site{" "}
                <i>needs</i>. This leaves the final result as a lean, performant
                and totally unique website to help you and your brand stand out.
              </Text>
              <Text fontSize="lg">
                It's the difference between buying a garment off the rack or
                getting an outfit tailored. Sure, off the rack works, but a
                tailored suit really makes you feel like a million bucks. Just
                ask James Bond.
              </Text>
            </Stack>
          </GridItem>
        </Grid>
      </Stack>

      <Grid templateColumns={["1fr", "repeat(12, 1fr)"]} gridGap={12}>
        <GridItem colSpan={[1, 4]}>
          <Text fontSize="2xl">
            Our team uses the latest engineering tools and technologies,
            employed by teams at the likes of Netflix, Spotify and AirBnb.
          </Text>
        </GridItem>
        <GridItem gridColumnStart={[0, 6]} colSpan={[1, 7]}>
          <Grid templateColumns={["1fr", "repeat(3, 1fr)"]} gridGap={[6]}>
            <Stack spacing={1}>
              <Heading size="md">React</Heading>
              <Text fontSize="lg">
                The most popular, and performant framework for building web
                applications by the team at Facebook
              </Text>
            </Stack>
            <Stack spacing={1}>
              <Heading size="md">TypeScript</Heading>
              <Text fontSize="lg">
                An improvement on JavaScript, this Microsoft made language helps
                us to create bug-free, performant code
              </Text>
            </Stack>
            <Stack spacing={1}>
              <Heading size="md">GraphQL</Heading>
              <Text fontSize="lg">
                The secret to our speed. GraphQL enables us to build out a CMS
                in the blink of an eye
              </Text>
            </Stack>
            <Stack spacing={1}>
              <Heading size="md">Gatsby</Heading>
              <Text fontSize="lg">
                Gatsby is the perfect fusion of React and GraphQL, that allows
                us to build blazing fast websites with massive SEO performance
              </Text>
            </Stack>
            <Stack spacing={1}>
              <Heading size="md">SwiftUI</Heading>
              <Text fontSize="lg">
                SwiftUI is the latest toolkit from Apple for building next-gen,
                native apps on iOS
              </Text>
            </Stack>
            <Stack spacing={1}>
              <Heading size="md">Node.js</Heading>
              <Text fontSize="lg">
                When we're working in the backend and wrangling your data, we
                use Node.js, the modern standard in back-end web development for
                React
              </Text>
            </Stack>
          </Grid>
        </GridItem>
      </Grid>

      <Heading>How we work</Heading>
      <Grid templateColumns={["1fr", "1fr 1fr 1fr"]} gridGap={6}>
        <Step
          number="1"
          title="Plan"
          description="We'll work with you to help nail down the details of what you're after. It's pivotal for us to understand what you're trying to achieve with your new product, and how we can ensure it creates the best impact"
        />
        <Step
          number="2"
          title="Design"
          description="Our engineers will work with your designers to provide feedback and revisions over the existing designs in order to create a high fidelity prototype we can trial before beginning development"
        />
        <Step
          number="3"
          title="Develop"
          description="The engineering team will begin building your product, true to the prototypes we've collaborated on, but with enough flexibility to adapt to any new requirements that pop up. We'll also give you access to the development version of the site so you can use it from day one."
        />
        <Step
          number="4"
          title="Deploy"
          description="We'll take care of the nitty gritty technical steps required in deploying your product out into the real world. Alongside this, we'll have a set of rigorous automatic tests that will flag any errors or bugs before they ever go live."
        />
        <Step
          number="5"
          title="Monitor"
          description="Our collection of tests and monitoring tools will ensure the product is healthy and online, and in the unlikely event of an issue, our monitoring tools will tell us exactly what the issue is so we can fix it immediately"
        />
        <Step
          number="6"
          title="Celebrate 🎉"
          description="This is the part where we invite you to the pub to sink some schooners and marvel at the product you've just released into the big wide world!"
        />
      </Grid>
    </Stack>
  )
}

interface StepType {
  title: string
  description: string
  number: string
}

const Step: React.FunctionComponent<StepType> = ({
  title,
  description,
  number,
}) => (
  <Stack>
    <HStack marginBottom={[0, 2]} alignItems="end">
      <Text color="#ff6464">0{number}.</Text>
      <Heading textTransform="uppercase" size="lg">
        {title}
      </Heading>
    </HStack>
    <Text fontSize="lg">{description}</Text>
  </Stack>
)

export default StudioSection
