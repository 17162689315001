import { graphql } from "gatsby"
import React from "react"
import Container from "../components/container"
import SEO from "../components/seo"
import Contact from "../components/shared/contact"
import { Project } from "../types"
import HeroSection from "../sections/home/hero"
import WorkSection from "../sections/home/work"
import StudioSection from "../sections/home/studio"

interface Props {
  data: {
    allContentfulProject: {
      nodes: Project[]
    }
  }
}

const Home: React.FunctionComponent<Props> = ({ data }) => {
  return (
    <Container>
      <SEO title="Home" />
      <HeroSection />
      <WorkSection projects={data.allContentfulProject.nodes} />
      <StudioSection />
      <Contact />
    </Container>
  )
}

export const query = graphql`
  query HomePageQuery {
    allContentfulProject(sort: { fields: dateCompleted, order: DESC }) {
      nodes {
        clientName
        website
        caseStudy {
          slug
        }
        coverImage {
          file {
            url
          }
        }
        services {
          title
        }
        description {
          description
        }
      }
    }
  }
`

export default Home
