import { Box, Heading, HStack, Image, Stack, Text } from "@chakra-ui/react"
import { Link } from "gatsby"
import React from "react"
import { Project } from "../../types"

const ProjectCard: React.FunctionComponent<Project> = ({
  clientName,
  coverImage,
  description,
  services,
  caseStudy,
  website,
}) => (
  <Stack spacing={[2, 4]}>
    {caseStudy ? (
      <Link to={`case-study/${caseStudy.slug}`}>
        <Box overflow="hidden" display="inline-block" width="100%">
          <Image
            src={coverImage.file.url}
            objectFit="cover"
            alt={caseStudy.title}
            width="100%"
            height="360px"
            transform="scale(1.05)"
            transition="1.2s all cubic-bezier(0, .28, .5, 1)"
            _hover={{ transform: "scale(1)" }}
          />
        </Box>
      </Link>
    ) : (
      <Box overflow="hidden" display="inline-block" width="100%">
        <Image
          src={coverImage.file.url}
          objectFit="cover"
          transform="scale(1.05)"
          width="100%"
          height="360px"
          transition="1.2s all cubic-bezier(0, .28, .5, 1)"
          _hover={{ transform: "scale(1)" }}
        />
      </Box>
    )}
    <Stack spacing={1} marginTop={8}>
      <Heading size="2xl">{clientName}</Heading>
      <HStack spacing={4} color="gray" display={["none", "none", "flex"]}>
        {services.map((service, i) => (
          <HStack spacing={4} key={service.title}>
            {i != 0 && <span>•</span>}
            <Text fontSize="lg" key={service.title}>
              {service.title}
            </Text>
          </HStack>
        ))}
      </HStack>
    </Stack>
    <Stack spacing={[0, 2]}>
      <Text fontSize="lg">{description.description}</Text>
      {caseStudy && (
        <Link to={`case-study/${caseStudy.slug}`}>
          <Text
            mt={4}
            display="inline-block"
            textTransform="uppercase"
            textDecoration="underline"
            _hover={{ textDecoration: "none" }}
            fontSize="xl"
          >
            View Case Study
          </Text>
        </Link>
      )}
      {website && !caseStudy && (
        <a href={website}>
          <Text
            display="inline-block"
            mt={4}
            textTransform="uppercase"
            textDecoration="underline"
            _hover={{ textDecoration: "none" }}
            fontSize="xl"
          >
            Visit Site
          </Text>
        </a>
      )}
    </Stack>
  </Stack>
)

export default ProjectCard
